<template>
  <div class="app-wrapper">
    <div id="ruler"></div>
    <div class="top-bar">
      <div class="menu-left-section">
        <a href="/" class="logo">
        </a>
      </div>
      <div class="menu-center-section menu-hide-mobile">
        <input @change="handlePdfChange" type="file" id="pdf" name="pdf" accept="application/pdf"
          style="display: none;" />
        <div>
          <label class="upload-link" @click="toggleUploadDiv()" :class="{ 'active-link': showUploadDiv }">Upload PDF</label>
        </div>
        <div>
          <button class="draw-link" @click="toggleDrawing()" :class="{ 'active-link active-link-pointer': drawState }">Draw Polygon</button>
        </div>
        <label class="get-result" @click="showResultsTable()" :class="{ 'active-link active-link-pointer': showResults }">Get Results</label>
      </div>
      <div class="menu-right-section">
        <input type="checkbox" id="menu-toggle" class="menu-toggle" />
        <label for="menu-toggle" class="hamburger-menu">
          <div class="line"></div>
          <div class="line"></div>
          <div class="line"></div>
        </label>
        <div class="menu-side-menu">


          <div class="test-side-menu menu-show-mobile">
          <input @change="handlePdfChange" type="file" id="pdf" name="pdf" accept="application/pdf"
          style="display: none;" />
        <div>
          <label class="upload-link" @click="toggleUploadDiv()" :class="{ 'active-link': showUploadDiv }">Upload PDF</label>
        </div>
        <div>
          <button class="draw-link" @click="toggleDrawing()" :class="{ 'active-link active-link-pointer': drawState }">Draw Polygon</button>
        </div>
        <label class="get-result" @click="showResultsTable()" :class="{ 'active-link active-link-pointer': showResults }">Get Results</label>
      </div>



          <a href="https://www.geoplastglobal.com/en/contacts/" class="menu-link" target="_blank">Contact Us</a>
          <a href="https://www.geoplastglobal.com/en/products/water/aquabox/" class="menu-link" target="_blank">Aquabox</a>
          <a href="https://www.geoplastglobal.com/en/products/water/aquabox/calculator/" class="menu-link" target="_blank">Aquabox Calculator</a>
          
          <a href="https://www.geoplastglobal.com/en/" class="menu-link side-menu-sites-links">GEOPLASTGLOBAL.COM</a>
          <a href="https://portal.geoplastglobal.com/en/login" class="menu-link">PORTAL.GEOPLASTGLOBAL.COM</a>

          <div class="social-wraper">
            <a href="https://www.facebook.com/geoplast" target="_blank"><i class="fa-brands fa-facebook-f"></i></a>
            <a href="https://x.com/geoplast" target="_blank"><i class="fa-brands fa-x-twitter"></i></a>
            <a href="https://www.youtube.com/user/GEOPLASTspa" target="_blank"><i class="fa-brands fa-youtube"></i></a>
            <a href="https://www.linkedin.com/company/geoplast-spa" target="_blank"><i
                class="fa-brands fa-linkedin-in"></i></a>
            <a href="https://www.instagram.com/geoplastglobal/" target="_blank"><i class="fa-brands fa-instagram"></i></a>
            <a class="oi_icon" href="https://openinnovation.me/" target="_blank">
              <svg width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41.49 44.16">
                <path class="cls-1"
                  d="M277.25,426c0-9.58,5.77-16.5,15.84-16.5s15.83,6.92,15.83,16.5-5.76,16.56-15.83,16.56S277.25,435.67,277.25,426ZM302,426c0-5.4-2.73-11-8.91-11s-8.92,5.64-8.92,11,2.73,11.1,8.92,11.1S302,431.48,302,426Z"
                  transform="translate(-277.25 -398.42)" />
                <path class="cls-1" d="M311.83,398.42h6.92V405h-6.92Zm0,11.95h6.92v31.36h-6.92Z"
                  transform="translate(-277.25 -398.42)" />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>


    <div v-if="scaleSetPopup" class="scale-popup-overlay">
      <div class="popup">
        <h2>The scale is now set to 1 {{unitText}} / {{scale}} {{unitText}}.<br>Please be patient while the changes load.</h2>
        <!-- <button @click="scaleSetPopup=false;aquaboxScale=calculatedScale">OK</button> -->
        <button 
          ref="scaleSetPopupButton"
          @click="scaleSetPopup=false;aquaboxScale=calculatedScale;scaleBorderRed=true;" 
        >
          OK
        </button>
      </div>
    </div>

    <drawing-canvas @loadedPdf="drawState = true; this.targetPage=null" @closePdf="onClosePdf"
      :units="this.units" :unitText="this.unitText" :calculatedScale = "calculatedScale" :scaleBorderRed = "scaleBorderRed"
      @ScaleBorderRedChange="handleScaleBorderRedChange" @updateScaleByLine="handleScaleUpdateByLine"
      :aquaboxScale="this.aquaboxScale" :counters="this.counters" :pdfFile="pdf" :targetPage="targetPage" :results="results" :scaleSetPopup="scaleSetPopup"
      :drawState="drawState" :scaleState="scaleState" @caluclations="calc => handleCalculations(calc)" @clearCalculations="this.results = null" />

    <pdf-picker :pdfSrc="pdfSource" @selectPage="number => targetPage = number" />

    <div class="scale" :class="{'scale-border-red': scaleBorderRed}" >
      <div class="units-option">
        <p>Choose your units: &nbsp;&nbsp;</p>
        <input type="radio" class="metric-checkbox custom-checkbox" name="unit" value="metric" v-model="units" @change="toggleUnits('metric')">
        <label for="metric-radio">Metric</label>
        <input type="radio" class="imperial-checkbox custom-checkbox" name="unit" value="imperial" v-model="units" @change="toggleUnits('imperial')">
        <label for="imperial-radio">Imperial</label>
      </div>
      <div class="scale-input">
        <p>SCALE: 1 {{ unitText }} / &nbsp;</p>
        <!-- <input v-model="scale" type="text" :placeholder="placeholderText"> -->
        <input v-if="units === 'metric'" v-model="scale" type="text" :placeholder="'70'">
        <input v-else v-model="scale" type="text" :placeholder="'27.56'">
        <p>&nbsp; {{ unitText }} &nbsp;</p>
        <button class="scale-button" @click="calculateScale()"><span>Set scale</span></button>
      </div>
    </div>

    <div class="upload-div" :class="{ 'hidden': !showUploadDiv }">
      <div class="upload-content">
        <p>Please upload PDF Document</p>
        <div class="upload-button">
          <button @click="openFileUploadDialog"><span>Choose File</span></button>
        </div>
      </div>
    </div>

    <!-- Table.vue component -->
    <Table @dropdownChange="data => refreshCalculations(data)" :results="results" :displayTable="showResults" :units="units" />
      <!-- <PdfExport/> -->
  </div>
</template>

<script>

import DrawingCanvas from './components/DrawingCanvas.vue'
import PdfPicker from './components/PdfPicker.vue'
import Table from './components/Table.vue'
import PdfExport from './components/PdfExport.vue'
import Calculator from './assets/lib/calculator'

export default {
  components: { DrawingCanvas, PdfPicker, Table, PdfExport },
  name: 'App',
  data() {
    return {
      pdfSource: '',
      counters: {
        aquabox: {
          A0: 0,
          A1: 0,
          A2: 0,
          A3: 0,
          A4: 0
        },
        cube: {
          C0: 0,
          C1: 0,
          C2: 0,
          C3: 0,
          C4: 0
        }
      },
      pdf: null,
      targetPage: null,
      drawState: false,
      scaleState: false,
      scale: null,
      hasPolygon: false,
      aquaboxScale: 0.8500781250000001,
      showUploadDiv: true,
      showResults: false,
      isMetric: true,
      isImperial: false,
      units: "metric",
      unitText: "cm",
      scaleCm: null,
      scaleIn: null,
      displayUpload: true,
      displayTable: false,
      results: null,
      dropdowns: null,
      scaleSetPopup: false,
      calculatedScale: null,
      scaleBorderRed: false,
      timeoutId: null,
    }
  },

  mounted() {
    this.setInitialScale();  
  },

  methods: {
    handlePdfChange(e) {
      const selectedFile = e.target.files[0];
      if (selectedFile) {
        this.pdf = selectedFile;
        const url = URL.createObjectURL(selectedFile);
        this.pdfSource = url;
        this.toggleDrawing();
      } else {
        // Handle the case where no file is selected or the user cancels the upload.
        console.error('No file selected or upload canceled.');
      }
    },
    onClosePdf(){
      this.pdfSource = '';
    },  
    handleCalculations(calc){
      this.results = calc;
    },
    showResultsTable() {
      this.displayUpload= false;
      this.drawState = false;


      if (!this.showResults) {
        // Only toggle the showResults variable when you want to show the table
        this.showResults = true;
      }

      // Set visibility of the table
      this.displayUpload= true;

      // Hide the upload div
      this.showUploadDiv = false;
    },
    toggleDrawing(){

      this.showResults = false;
      this.showUploadDiv = false;
      if(this.pdfSource == ''){
        this.drawState = true;
        this.scaleState = true;
      }
    },
    calculateScale() {

      if (this.units === 'metric') {
        this.scaleCm = this.scale;
        this.scaleIn = (this.scale / 2.54).toFixed(2);
      } else {
        this.scaleCm = (this.scale * 2.54).toFixed(2);
        this.scaleIn = this.scale;
      }

      var addClass = document.querySelector('.scale-loader');

    // Add a class to the button
    // addClass.classList.add('open');

    
      // Get 1cm in pixels
      let cm = document.getElementById('ruler').getClientRects()[0].width;
      // cm /= 100;
      console.log('cm', cm);

      // Get one aquabox side in pixels 1:1
      cm *= 0.75;
      console.log('aquabox side', cm)
      // Get Aquabox side in scale
      cm /= this.scaleCm;
      cm *= 100;
      console.log('aquabox side in scale', cm)

      // let pixel = cm * 75;
      // let scaled = pixel;

      // Initial aquabox images size 50x50px
      let initialScale = 50;
      
      let calculatedScale = cm / initialScale;

      console.log('scale by', calculatedScale);

      // console.log(calculatedScale);

      if (calculatedScale === this.aquaboxScale) {
        addClass.classList.remove('open');
      } else {
        this.resetCounters();
      }

      console.log(calculatedScale);

      
      if (this.dropdowns) {
        this.refreshCalculations(this.dropdowns);
      } else {
        this.refreshCalculations();
      }
      
      // alert(`The scale is now set to 1${this.unitText}/${this.scale}${this.unitText}. \nPlease be patient while the changes load.`);
      this.scaleSetPopup = true;
      this.calculatedScale = calculatedScale;
    },

    setInitialScale() {

      // initial scale cm
      this.scaleCm = 100;

      // initial scale in
      this.scaleIn = 39.37;

      // replace placeholder with value for cm
      this.scale = 100;

      // Get 1cm in pixels
      let cm = document.getElementById('ruler').getClientRects()[0].width;
      
      // Get one aquabox side in pixels 1:1
      cm *= 0.75;

      // Get Aquabox side in scale
      cm /= this.scaleCm;
      cm *= 100;

      // Initial aquabox images size 50x50px
      let initialScale = 50;

      let calculatedScale = cm / initialScale;

      this.aquaboxScale = calculatedScale;

    },

    resetCounters(){
      this.counters = {
        aquabox: {
          A0: 0,
          A1: 0,
          A2: 0,
          A3: 0,
          A4: 0
        },
        cube: {
          C0: 0,
          C1: 0,
          C2: 0,
          C3: 0,
          C4: 0
        }
      }
    },

    refreshCalculations(data=null){
      console.log('updated dropdowns')
      this.dropdowns = data;
      if (data){
        this.results = new Calculator(this.counters, data).calculateAll();
      } else {
        this.results = new Calculator(this.counters).calculateAll();
      }
    },

    toggleUploadDiv() {
      this.drawState = false;
      this.showResults = false;

      this.displayUpload=true;
      
      this.showUploadDiv = !this.showUploadDiv;

      this.displayTable=false

    },
    openFileUploadDialog() {
      const fileInput = this.$el.querySelector('#pdf');
      if (fileInput) {
        fileInput.click(); // Trigger the file input click event
      }
      // alert('Drawing scale set to 1cm/'+this.scale+'m');

    },
    toggleUnits(unit) {
      this.units = unit;
      if (unit === 'metric') {
        this.unitText = ' cm'; 
        this.scale = this.scaleCm;
      } else {
        this.unitText = ' in';
        this.scale = this.scaleIn;
      }
    },
    handleScaleBorderRedChange(val) {
      this.scaleBorderRed = val;
    },
    handleScaleUpdateByLine(val) {
      
      let valCm = val;
      
      if (this.units === 'metric') {
      this.scale = valCm;
      } else {
        this.scale = (valCm / 2.54).toFixed(2);
      }

      this.scaleCm = valCm;
      this.scaleIn = (valCm / 2.54).toFixed(2);
    }
  },
  watch:{
    // scaleSetPopup(val) {
    //     if (val) {
    //       this.$nextTick(() => {
    //         this.$refs.scaleSetPopupButton.focus();
    //       });
    //     }
    //   },

    scaleBorderRed(val) {
     
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      if (val) {
        this.timeoutId = setTimeout(() => {
          document.querySelector('.scale').classList.add('fade-out');

          setTimeout(() => {
            this.scaleBorderRed = false;
          }, 2000);
        }, 500);
      }
    },
  },
  computed:{
    drawText(){
      return this.drawState ? 'Drawing...' : 'Draw polygon'
    },
    // placeholderText() {
    //   // Change placeholder based on unit
    //   return this.units === 'metric' ? '5000' : '1968.5';
    // }
  }
}

// Open and close menu
document.addEventListener('DOMContentLoaded', function () {
  const menuToggle = document.querySelector('.menu-toggle');
  const sideMenu = document.querySelector('.menu-side-menu');
  const hamburgerMenu = document.querySelector('.hamburger-menu');

  menuToggle.addEventListener('change', function () {
    if (menuToggle.checked) {
      // Show the menu
      sideMenu.style.top = '0';
      sideMenu.style.visibility = 'visible';
      sideMenu.style.opacity = '1';
      sideMenu.style.transition = 'top 0.3s, visibility 0s, opacity 0.3s';

      // Reset the transition property
      setTimeout(() => {
        sideMenu.style.transition = 'none';
      }, 300); // After the transition duration (0.3s in this case)
    } else {
      // Hide the menu with a reverse transition
      sideMenu.style.top = '-100%';
      sideMenu.style.visibility = 'hidden';

      // Add the reverse transition
      sideMenu.style.transition = 'top 0.3s, visibility 0.3s';

      // Reset the transition property after a delay
      setTimeout(() => {
        sideMenu.style.transition = 'none';
      }, 300); // After the reverse transition duration (0.3s in this case)
    }
  });

  //default scale
  let cm = document.getElementById('ruler').getClientRects()[0].width;

  let aquaboxRealLifeSize = cm * 0.75;

  console.log(50 / aquaboxRealLifeSize);





  // Close the side menu when clicking outside
  document.addEventListener('click', function (event) {
    if (
      event.target !== sideMenu &&
      event.target !== hamburgerMenu &&
      event.target !== menuToggle &&
      !hamburgerMenu.contains(event.target)
    ) {
      menuToggle.checked = false;
      // Hide the menu with the reverse transition
      sideMenu.style.top = '-100%';
      sideMenu.style.visibility = 'hidden';
      sideMenu.style.transition = 'top 0.3s, visibility 0.3s';

      // Reset the transition property after a delay
      setTimeout(() => {
        sideMenu.style.transition = 'none';
      }, 300); // After the reverse transition duration (0.3s in this case)
    }
  });
});

</script>

<style lang="scss">
@import './assets/styles/main.scss';
</style>