<template>
  <div :class="{open: isShowing}" class="pdf-wraper" id="pdf-export">
  <div class="loader" :class="{open: loading}">
   Your download will start soon.
  </div>
    <div class="inner" ref="inner" :style="innerStyles">
      <div class="card" v-for="card in cards" :key="card" :id="`card-${card.id}`">
        <img :src= "card.src" >
        <img class="snapshot-image" v-if="card.snapShot" :src='this.snapshot' />
        <pdf-table v-if="card.results" :results="results"></pdf-table>
      </div>
    </div>
    <div id="export">
      <div class="card" v-for="card in exportCards" :key="card" :id="`card-${card.id}`">
        <img :src= "card.src" >
        <img class="snapshot-image" v-if="card.snapShot" :src='this.snapshot' />
        <pdf-table :isExport="true" v-if="card.results" :results="results"></pdf-table>
      </div>
    </div>
    <button class="btn btn-back" @click="prev">prev</button>
    <button class="btn btn-download" @click="downloadPdf()">Download</button>
    <button class="btn btn-forward" @click="next">next</button>
    <button class="btn btn-close" @click="closePdfPreview()"><i class="fa-regular fa-x"></i></button>
  </div>
</template>

<script>
import PdfTable from "./PdfTable.vue"
import html2pdf from "html2pdf.js";
import axios from 'axios'

export default {
  name: 'pdf-export',
  
  components: {PdfTable},

  props: {
    results: null,
    aquaboxST: 'Pedestrian',
    levels: 1,
    typeOfTank: 'Dispersing',
    isShowing: false,
  },
  data () {
    return {
      innerStyles: {},
      step: '',
      transitioning: false,
      snapshot: '',
      loading: false
    }
  },

  mounted () {
    this.setStep()
    this.resetTranslate()
    this.emitter.on('sendSnapshot', snapshot =>{
      this.snapshot = snapshot;
    })
  },

  computed: {
    cards(){
      let arr = [
        {
          src: require('../assets/images/00_Cover.png'),
          id:'1'
          
        },
        {
          src: require('../assets/images/01_General_conditions.png'),
          id:'2'
        },
        {
          src: require('../assets/images/02_General_conditions.png'), 
          id:'3'
          
        },
        {
          src: require('../assets/images/03_Layout_and_Quantities.png'), 
          results: true,
          snapShot: true,
          id:'4'

        },
        {
          src: require('../assets/images/04_Accessories.png'),
          id:'5'
          
        }
        ]

        if (this.typeOfTank === 'Dispersing')
          switch (this.levels) {
            case "1":{
              arr.push(
                {
                  src: require('../assets/images/05_Dispersing_Tank_1Levels.png'),
                  id:'6'
                  
                }
              )
              break;
            } 
            case "1.5":{
              arr.push(
                {
                  src: require('../assets/images/05_Dispersing_Tank_1.5Levels.png'),
                  id:'6'
                  
                }
              )
              break;
            } 
            case "2":{
              arr.push(
                {
                  src: require('../assets/images/05_Impermeable_Tank_2Levels.png'),
                  id:'6'
                  
                }
              )
              break;
            } 
            case "2.5":{
              arr.push(
                {
                  src: require('../assets/images/05_Impermeable_Tank_2.5Levels.png'),
                  id:'6'
                  
                }
              )
              break;
            } 
            case "3":{
              arr.push(
                {
                  src: require('../assets/images/05_Dispersing_Tank_3Levels.png'),
                  id:'6'
                  
                }
              )
              break;
            } 
          } 
        else 
          switch (this.levels) {
            case "1":{
              arr.push(
                {
                  src: require('../assets/images/05_Impermeable_Tank_1Levels.png'),
                  id:'6'
                  
                }
              )
              break;
            } 
            case "1.5":{
              arr.push(
                {
                  src: require('../assets/images/05_Impermeable_Tank_1.5Levels.png'),
                  id:'6'
                  
                }
              )
              break;
            } 
            case "2":{
              arr.push(
                {
                  src: require('../assets/images/05_Dispersing_Tank_1Levels.png'),
                  id:'6'
                  
                }
              )
              break;
            } 
            case "2.5":{
              arr.push(
                {
                  src: require('../assets/images/05_Dispersing_Tank_1Levels.png'),
                  id:'6'
                  
                }
              )
              break;
            } 
            case "3":{
              arr.push(
                {
                  src: require('../assets/images/05_Impermeable_Tank_3Levels.png'),
                  id:'6'
                  
                }
              )
              break;
            } 
        }
      return arr;
    },
    exportCards(){
      let arr = [
        {
          src: require('../assets/images/00_Cover.png'),
          id:'1'
          
        },
        {
          src: require('../assets/images/01_General_conditions.png'),
          id:'2'
        },
        {
          src: require('../assets/images/02_General_conditions.png'), 
          id:'3'
          
        },
        {
          src: require('../assets/images/03_Layout_and_Quantities.png'), 
          results: true,
          snapShot: true,
          id:'4'

        },
        {
          src: require('../assets/images/04_Accessories.png'),
          id:'5'
          
        }
        ]

        if (this.typeOfTank === 'Dispersing')
          switch (this.levels) {
            case "1":{
              arr.push(
                {
                  src: require('../assets/images/05_Dispersing_Tank_1Levels.png'),
                  id:'6'
                  
                }
              )
              break;
            } 
            case "1.5":{
              arr.push(
                {
                  src: require('../assets/images/05_Dispersing_Tank_1.5Levels.png'),
                  id:'6'
                  
                }
              )
              break;
            } 
            case "2":{
              arr.push(
                {
                  src: require('../assets/images/05_Impermeable_Tank_2Levels.png'),
                  id:'6'
                  
                }
              )
              break;
            } 
            case "2.5":{
              arr.push(
                {
                  src: require('../assets/images/05_Impermeable_Tank_2.5Levels.png'),
                  id:'6'
                  
                }
              )
              break;
            } 
            case "3":{
              arr.push(
                {
                  src: require('../assets/images/05_Dispersing_Tank_3Levels.png'),
                  id:'6'
                  
                }
              )
              break;
            } 
          } 
        else 
          switch (this.levels) {
            case "1":{
              arr.push(
                {
                  src: require('../assets/images/05_Impermeable_Tank_1Levels.png'),
                  id:'6'
                  
                }
              )
              break;
            } 
            case "1.5":{
              arr.push(
                {
                  src: require('../assets/images/05_Impermeable_Tank_1.5Levels.png'),
                  id:'6'
                  
                }
              )
              break;
            } 
            case "2":{
              arr.push(
                {
                  src: require('../assets/images/05_Dispersing_Tank_1Levels.png'),
                  id:'6'
                  
                }
              )
              break;
            } 
            case "2.5":{
              arr.push(
                {
                  src: require('../assets/images/05_Dispersing_Tank_1Levels.png'),
                  id:'6'
                  
                }
              )
              break;
            } 
            case "3":{
              arr.push(
                {
                  src: require('../assets/images/05_Impermeable_Tank_3Levels.png'),
                  id:'6'
                  
                }
              )
              break;
            } 
        }
      return arr;
    }
  },

  methods: {
    detectChange(){
        this.$emit('dropdownChange', {
            AquaboxST: this.AquaboxST,
            Levels: this.Levels,
            TypeOfTank: this.TypeOfTank
        })
    },
    setStep () {
      const innerWidth = this.$refs.inner.scrollWidth
      const totalCards = this.cards.length
      this.step = `${innerWidth / totalCards}px`
    },

    next () {
      if (this.transitioning) return

      this.transitioning = true

      this.moveLeft()

      this.afterTransition(() => {
        const card = this.cards.shift()
        this.cards.push(card)
        this.resetTranslate()
        this.transitioning = false
      })
    },

    prev () {
      if (this.transitioning) return

      this.transitioning = true

      this.moveRight()

      this.afterTransition(() => {
        const card = this.cards.pop()
        this.cards.unshift(card)
        this.resetTranslate()
        this.transitioning = false
      })
    },

    async downloadPdf(){
      this.loading = true;
      
      let timestamp = new Date();
      timestamp = Math.floor((timestamp.getTime() / 1000)).toString();

      let options = {
        setTestTdInOnePage: false,
          margin: [0, 0, -1, 0],
          filename: `aquabox-report-${timestamp}`,
          image: {
            type: "png",
          },
          html2canvas: {
            dpi: 5000,
          },
          jsPDF: {
            unit: "mm",
            format: "a3",
            compress: true,
            orientation: "landscape"
          }
        }
        console.log(process.env.VUE_APP_BACKEND_LOCATION)
        let file = await html2pdf().from(document.getElementById('export')).set(options).save().outputPdf().then(res =>{
          let data = {
            pdfData: btoa(res),
            timestamp : timestamp
          }
          axios.post(`${process.env.VUE_APP_BACKEND_LOCATION}`,data,{ headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          }})
          .then(res =>{
            console.log(res)
            
            })
          .catch(err => console.log(err))

        })

        setTimeout(() => {
          this.loading=false;
        }, 2000);
    },

    moveLeft () {
      this.innerStyles = {
        transform: `translateX(-${this.step})
                    translateX(-${this.step})`
      }
    },

    moveRight () {
      this.innerStyles = {
        transform: `translateX(${this.step})
                    translateX(-${this.step})`
      }
    },

    afterTransition (callback) {
      const listener = () => {
        callback()
        this.$refs.inner.removeEventListener('transitionend', listener)
      }
      this.$refs.inner.addEventListener('transitionend', listener)
    },

    resetTranslate () {
      this.innerStyles = {
        transition: 'none',
        transform: `translateX(-${this.step})`
      }
    },

    closePdfPreview() {
      this.$emit("closePdfPreview")
    }
  }
};
</script>

<style scss>
 .pdf-image img {
    width: 100%;
  }
    .loader{
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 30%;
      background: rgba(0,0,0,0.5);
      color: white;
      font-size: 30px;
      font-weight: bold;
      display: grid;
      place-items: center;
      z-index: 1;
      font-family: Arial, sans-serif;
      display: none;

      &.open{
        display: grid;
      }
    }
  .btn {
    position: absolute;
    padding: 10px 20px;
    border: none;
    z-index: 10px;
    cursor: pointer;
    background-color: #707070;
    font-size: 14px;
    text-transform: uppercase;
    color: #fff;
    font-family: helvetica;
  }
  .btn:hover {
    background-color: #16478b;
  }
  .btn-back {
    top: 90%;
    left: 2%;
  }
  .btn-forward {
    top: 90%;
    right: 2%;
  }
  .btn-download{
    top: 89%;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 20px;
    background-color: #f5842b;
    color: #fff;
    border: none;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 18px;
    font-family: helvetica;
    width: 300px;
    height: 50px;
  }
  .btn-close {
    top: 1%;
    right: 2%;
    padding: 10px 14px 8px 14px;
  }
  .open-pdf {
    padding: 10px 20px;
    background-color: #f5842b;
    color: #fff;
    border: none;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 18px;
    font-family: helvetica;
    width: 300px;
    height: 50px;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: 60px;
  }
  .pdf-wraper {
    top: 45%;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    
    left: 50%;
    /* width: 1200px; */
    aspect-ratio: 1.41 / 1;
    height: 700px;
    overflow: hidden;
    transform: translate(-50%, -50%);
    transition: .1s ease-in-out;
    &.open {
      top: 50%;
      opacity: 1;
      pointer-events: auto;
    }
  }
  .inner {
    white-space: nowrap; /* ❸ */
    transition: transform 0.2s; /* ❹ */

  }
  .card {
    /* width: 1000px; */
    display: inline-flex;
    position: relative;
    
    /* optional */
    background-color: #c1c1c1;
    color: white;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
  }
  .card img {
    width: 100%;
  }
  .card .snapshot-image{

    position: absolute;
    left: 2%;
    top:5%;
    max-width: 60%;
    max-height: 70%;
    width: auto;
  }

  /* optional */

</style>

