<template>
  <div class="drawing-canvas">
    <div class="canvas-buttons-wrap" :class="{'hidden': !drawState, 'active': isDrawing}">
    <button class="default-button toggle-draw-button" @click="isDrawing = true;" :class="{'hidden': !drawState,'active': isDrawing}"><span>Draw</span></button>
    <button class="default-button toggle-scale-button" @click="isScaling = true;" :class="{'hidden': !drawState,'active': isScaling}"><span>Scale</span></button>
    <button class="default-button toggle-draw-button" @click="resetPolygon();" :class="{'hidden': !group || !drawState}"><span>Reset</span></button>
  </div>
    <canvas
      ref="can"
      id="canvas-tools"
      class="canvas"
      
    ><canvas id="bg"></canvas></canvas>
    <div class="draw-data">
      <h4>Basin Data</h4>
      <div class="data-wrap">
        <div class="column column-label">Volume</div>
        <div v-if="units=='metric'" class="column column-value">{{results ? results.grossVolume : '/'}} m<sup>3</sup></div>
        <div v-if="units=='imperial'" class="column column-value">{{results ? results.grossVolumeCft : '/'}} cft</div>
      </div>
      <div class="data-wrap">
        <div class="column column-label">Surface</div>
        <div v-if="units=='metric'" class="column column-value">{{results ? results.tankSurface : '/'}} m<sup>2</sup></div>
        <div v-if="units=='imperial'" class="column column-value">{{results ? results.tankSurfaceSft : '/'}} sft</div>
      </div>
      <div class="data-wrap">
        <div class="column column-label">Length</div>
        <div class="column column-value">{{getDimensions.lenght}} {{ unitText }}</div>
      </div>
      <div class="data-wrap">
        <div class="column column-label">Width</div>
        <div class="column column-value">{{getDimensions.width}} {{ unitText }}</div>
      </div>
      <div class="data-wrap">
        <div class="column column-label">Layer number</div>
        <div class="column column-value">{{results ? results.dropdownAquaboxLevels : '1'}}</div>
      </div>
    </div>
  </div>
  <img style="display:none" id="aquabox" src="../assets/images/aquabox.png" alt="">
  <img style="display:none" id="cube" src="../assets/images/cube.png" alt="">
  <div class="scale-loader"></div>


  <!-- <div class="popup">
        <h2>Enter line length in {{ unitText }}</h2>
        <p><input v-model="lineLengthInput" type="number" placeholder="1050"></p>
        <button @click="setScaleByLine">SET SCALE</button>
      </div>
    </div> -->


<div v-if="isPopupVisible" class="scale-popup-overlay">
    <div class="popup">
      <form @submit.prevent="setScaleByLine">
        <h2>Enter line length in {{ unitText }}</h2>
        <p>
          <input 
          v-model="lineLengthInput" 
          type="number" 
          placeholder="1050" 
          ref="modalInput"
          @blur="validateInput"
          :class="{'input-error': !isValidLineLength && isTouched}"
          :min="1" 
          required
          aria-label="Line length input"
        >
        </p>

        <button 
          type="submit"
          :disabled="!isValidLineLength"
          :class="{'disabled-button': !isValidLineLength}"
          aria-disabled="true"
        >
          SET SCALE
        </button>
      </form>
    </div>
  </div>

  <div v-if="changesLoadedPopup" class="scale-popup-overlay">
  <div class="popup">
    <h2>Changes loaded</h2>
    <button 
      ref="changesLoadedButton"
      @click="changesLoadedPopup=false;toggleScaleBorderRed();"
    >
      OK
    </button>
  </div>
</div>

<div v-if="drawLinePopup" class="scale-popup-overlay">
  <div class="popup">
    <h2>Please draw a line and input its length on the plan (ex: 1050)</h2>
    <button 
      ref="drawLineButton"
      @click="drawLinePopup=false;"
    >
      OK
    </button>
  </div>
</div>

<div v-if="lineScaleSetPopup" class="scale-popup-overlay">
  <div class="popup">
    <h2>Aquabox scale is set relative to the drawn line</h2>
    <button 
      ref="lineScaleSetButton"
      @click="lineScaleSetPopup=false;toggleScaleBorderRed();" 
    >
      OK
    </button>
  </div>
</div>

</template>

<script>
import { fabric } from "fabric";
import Calculator from "/src/assets/lib/calculator.js"

const pdfHandler = require('pdfjs-dist/build/pdf')
const Base64Prefix = "data:application/pdf;base64,";
const pdfjs = require('pdfjs')

class Point {
  constructor(x, y) {
    this.x=x;
    this.y=y;
  }
}

export default {
  name: "drawing-canvas",
  props:{
    counters:{},
    pdfFile: null,
    targetPage: null,
    drawState: false,
    scaleState: false,
    aquaboxScale: 1,
    units: 'metric',
    unitText: 'cm',
    results: null,
    scaleSetPopup: false,
    calculatedScale: null,
    scaleBorderRed: Boolean,
  },
  data() {
    return {
      roof: null,
      roofPoints: [],
      lines: [],
      scalingLines: [],
      group: null,
      lineCounter: 0,
      lineCounterScale: 0,
      drawingLine: false,
      drawingObject: {
        type: "roof",
        background: "",
        border: "",
      },
      canvas: null,
      x: 0,
      y: 0,
      lineStart: {
        x:0,
        y:0
      },
      lineIsValid: true,
      isDragging: false,
      lastPosX: null,
      lastPosY: null,
      clickScale: 0.8500781250000001,
      isDrawing: false,
      isScaling: false,
      isPopupVisible: false,
      lineLength: 0,
      changesLoadedPopup: false,
      changesLoaded: false,
      drawLinePopup: false,
      lineScaleSetPopup: false,
      lineLengthInput: '',
      isValidLineLength: true,
      isTouched: false,
    };
  },

  mounted() {
    const ref = this.$refs.can;
    this.canvas = new fabric.Canvas(ref);

     this.emitter.on("getSnapshot", () => {
      if(this.group)
        this.getCanvasSnapshot();
     });

    let canvasWidth = document.getElementsByClassName('canvas-container')[0].getClientRects()[0].width;
    let canvasHeight = document.getElementsByClassName('canvas-container')[0].getClientRects()[0].height;
    this.canvas.on('mouse:wheel', e => this.handleZoom(e));
    this.canvas.on('mouse:down', e => this.handleStartPanning(e))


    this.canvas.on('mouse:wheel', e => this.handleZoom(e));
    this.canvas.on('mouse:down', e => this.handleStartPanning(e))
    this.canvas.on('mouse:move', e => this.handlePanning(e))
    this.canvas.on('mouse:up', e => this.handleStopPanning(e))
    
    this.canvas.set('renderOnAddRemove', true);
    // this.canvas.set('preserveObjectStacking', true);

    // this.canvas.set('objectCaching', false);
    this.canvas.setDimensions({width:canvasWidth,height:canvasHeight});    
  },

  methods: {
    

    toggleDrawing() {
        if (this.drawingObject.type == "roof") {
        this.drawingObject.type = "";
        let can = this.canvas;
        this.lines.forEach(function (value, index, ar) {
            can.remove(value);
        });
        this.roofPoints = this.makeRoof(this.roofPoints);
        this.canvas.add(this.roofPoints);
        this.canvas.renderAll();

        //clear arrays
        this.roofPoints = [];
        this.lines = [];
        this.lineCounter = 0;

      } else {
        this.drawingObject.type = "roof"; // roof type
        this.drawingLine = false;
      }
    },

    handleDoubleClick(){

      // Finish drawing last line
      this.roofPoints.push(new Point(this.x, this.y));
      var points = [this.x, this.y, this.x, this.y];

      let line = new fabric.Line(points, {
          strokeWidth: 3,
          selectable: false,
          stroke: 'blue',
        })
      
      this.lines.push(line);
      this.canvas.add(this.lines[this.lineCounter]);
      this.lineCounter++;

      

      if (this.drawingObject.type == "roof") {
        this.drawingObject.type = "";
        let can = this.canvas;

        this.lines.forEach(function (value, index, ar) {
          can.remove(value);
        });
        this.roofPoints.pop(this.roofPoints.length -1)
        this.lineCounter--;
        this.roofPoints = this.makeRoof(this.roofPoints);
        this.canvas.__eventListeners = {}

        this.canvas.add(this.roofPoints)
        this.group = new fabric.Group([],{
          height: this.roofPoints.height,
          width: this.roofPoints.width,
          left: this.roofPoints.left,
          top: this.roofPoints.top,
          hasControls: false,
        })
        let containerPoints = this.getPolygonPoints(this.roofPoints)
        let aquaboxGenerationData = this.generateAquabox(this.roofPoints, containerPoints);

       for(let item of aquaboxGenerationData.items){
         this.determineAquaboxType(item,aquaboxGenerationData.array);
       }
        
      this.group.add(this.roofPoints)

			for(let item of aquaboxGenerationData.items){
				this.group.add(item)
				this.canvas.remove(item)
			}
       
      this.canvas.remove(this.roofPoints)
      this.canvas.add(this.group); // Render polygon with aquabox


      this.canvas.off('mouse:down')
      
      let container = this.group.item(0);
      
      container.left = -(this.group.width /2);
      container.top = -(this.group.height /2);

      let lengths = this.showLineLenghts(containerPoints);

      for(let item of lengths){
        this.group.add(item)
      }
			let lineStartIndex = this.group._objects.length- lengths.length;


      for(let i = 1; i < this.group._objects.length; i++){
        let item = this.group.item(i);
        if(item.aquabox_type){
          item.left = -(this.group.width /2) + item.idPos[1]*(50 * this.clickScale);
          item.top= -(this.group.height /2) + item.idPos[0]*(50 * this.clickScale);
        }
        else{
          let deltaX = Math.abs(this.group.left - item.left);
          let deltaY = Math.abs(this.group.top - item.top);

          let offsetX = deltaX < 1 ? deltaX - item.width - 15  : deltaX - item.width/2;
          let offsetY = deltaY < 1 ? deltaY - item.height - 10  : deltaY - item.height/2;
					
					item.deltaX = deltaX;
					item.deltaY = deltaY;

						if(deltaX > 1 || deltaY > 1 && (i > lineStartIndex+1)){
						if(item.deltaX == this.group.item(lineStartIndex+1).deltaX){
							offsetY +=  item.height/2 + 10;
						}
						if(item.deltaY == this.group.item(lineStartIndex).deltaY){
							offsetX += item.width/2 + 10;
						}
					}

          let adjustedLeft = -(this.group.width /2) + offsetX;
          let adjustedTop = -(this.group.height /2) + offsetY;
          

          item.left = adjustedLeft;
          item.top= adjustedTop;

          if(item.top < 0 && item.deltaX == this.group.item(lineStartIndex+1).deltaX){
						item.top -= item.height;
					}
     
        }
      }


      let calulations = new Calculator(this.counters).calculateAll();
      this.$emit('caluclations', calulations);
      
      
      // First bug (Fix rendering issues (Not showing aquabox elements on click))
      this.canvas.renderAll();
      
      //clear arrays
      this.roofPoints = [];
      this.lines = [];
      this.lineCounter = 0;
      this.drawingObject.type = "";
      this.$emit('stopDrawing');
      this.canvas.on('mouse:wheel', e => this.handleZoom(e));
      document.removeEventListener('keydown',this.handleKeyDown);
      
    }
    this.resetDrawing();
    this.canvas.on('mouse:down', e => this.handleTargetClick(e));
      
    },

    showLineLenghts(points){

      let array = []
      for( let i = 0; i < points.length-1; i++){
        // x and y for the first point
        let xPoint1 = points[i][0];
        let yPoint1 = points[i][1];

        // x and y for the second point
        let xPoint2;
        let yPoint2;

        // if last point, compare with first
        if(i == points.length-1){
           xPoint2 = points[0][0];
           yPoint2 = points[0][1];
        }
        else{
           xPoint2 = points[i+1][0];
           yPoint2 = points[i+1][1];
        }

        
        let distance;
        let direction;
        // either x or y is different since we're always using a right angle
        if(xPoint1 == xPoint2){
          distance = Math.abs(yPoint1 - yPoint2);
          //direction = 'vertical'
        }
        else{
          distance = Math.abs(xPoint1 - xPoint2);
        }

        let angle;
        switch(direction){
          case 'vertical':
            angle = 90;
          case 'default':
            angle = 0;
            break;
        }


        let output = `${this.calculateLineText(distance)} ${this.unitText}`
        let text = new fabric.Text(output, {
          left: (xPoint1 + xPoint2) / 2,
          top: (yPoint1 + yPoint2) / 2,
          fontSize: 20,
          fontFamily: 'Arial', 
          angle: direction == 'vertical' ? 90 : 0,
          fill: 'blue',
          selectable: false,
					distanceToCalculate: distance
        });
        this.canvas.add(text);
        array.push(text)
      }

      return array;
    },

    calculateLineText(distance){
      let compare = 50 * this.clickScale;
      let ratio = distance / compare;
			if(this.units === 'metric') return Math.round(ratio*75);
			else return Math.round(ratio*75/2.54);
    },

    handleDoubleClickScale(){
      this.isScaling = false;

      //TODO: add popup for scale
      // document.querySelector('.scale-line-cm').classList.add('scale-line-open');
      this.isPopupVisible = true;

      // line length in px
      if(this.scalingLines[0].width != 0){
        this.lineLength = this.scalingLines[0].width;
      }
      else{ // if the line is vertical
        this.lineLength = this.scalingLines[0].height;
      }

    },
    setScaleByLine(){
      // we get this from input, line length in cm
      let lineLengthInput = this.lineLengthInput;
      
      // clear input field so the popup will show again
      this.lineLengthInput = '';

      let lineLengthInputCm;

      if (this.units === 'metric') {

        lineLengthInputCm = lineLengthInput;

      } else {

        lineLengthInputCm = lineLengthInput * 2.54;

      }

      // we calculate aquabox slide relative to line length
      let targetSideLength = (this.lineLength * 75) / lineLengthInputCm;

      // we calculate scale, aquabox image is 50px * 50px
      this.clickScale = targetSideLength / 50;

      console.log('aquabox scale by line', this.clickScale);

      // Get 1cm in pixels
      let cm = document.getElementById('ruler').getClientRects()[0].width;

      let scaleCm = (cm * 0.75 * 100) / (this.clickScale * 50);

      scaleCm = scaleCm.toFixed(2);

      this.$emit('updateScaleByLine', scaleCm);
    
      console.log('scale line in cm', scaleCm);

      let can = this.canvas;

      this.scalingLines.forEach(function (value, index, ar) {
        can.remove(value);
      });
      
      this.scalingLines = []
      
      this.resetDrawing();
      
      if(this.group){
        this.scaleDownAquabox(this.clickScale);
        can.on('mouse:down', e => this.handleTargetClick(e));
      }
      document.removeEventListener('keydown',this.handleKeyDownScale);
      // document.addEventListener('keydown',this.handleKeyDown); //
      
      can.renderAll();
      //TODO: Add alert
      // alert(`Aquabox scale is set relative to the drawn line.`)
      this.lineScaleSetPopup = true;
      this.isPopupVisible = false;

    },

    handleKeyDown(e){
      if(e.key === 'Enter' && !this.isPopupVisible && !this.drawLinePopup && !this.lineScaleSetPopup && !this.scaleSetPopup && !this.changesLoadedPopup){
        this.handleDoubleClick(e);
      }
    },
    handleKeyDownScale(e){
      if(e.key === 'Enter' && !this.isPopupVisible && !this.drawLinePopup && !this.lineScaleSetPopup && !this.scaleSetPopup && !this.changesLoadedPopup){
        this.handleDoubleClickScale(e);
      }
    },

    resetDrawing(){
      this.drawingObject.type="roof";
      this.roofPoints = [];
      this.scalingLines = [];
      this.sca
      this.x = 0,
      this.y= 0,
      this.lineStart= {
          x:0,
          y:0
      };
      this.drawingLine = false;

      this.canvas.off('mouse:up');
      this.canvas.off('mouse:down');
      this.canvas.off('mouse:move');
      this.canvas.off('mouse:dblclick');
    

      this.canvas.on('mouse:wheel', e => this.handleZoom(e));
      this.canvas.on('mouse:down', e => this.handleStartPanning(e))
      this.canvas.on('mouse:move', e => this.handlePanning(e))
      this.canvas.on('mouse:up', e => this.handleStopPanning(e))
    },
    handleTargetClick(options){
      let pos = this.canvas.getPointer(options.e);
      for(let i = 1; i < this.group._objects.length; i++){
        if(!this.group.item(i).aquabox_type) return
        
        let aquabox = this.group.item(i);
        
        if(this.checkIfClicked(aquabox,pos)){
          if(aquabox.aquabox_type.includes('A')){
              this.counters.aquabox[aquabox.aquabox_type]--;
              aquabox.set('aquabox_type',aquabox.aquabox_type.replace('A', 'C'));
              this.counters.cube[aquabox.aquabox_type]++;
              aquabox.item(0).set('opacity',0);
              aquabox.item(1).set('opacity',1);

            }
            else{
              this.counters.cube[aquabox.aquabox_type]--;
              aquabox.set('aquabox_type',aquabox.aquabox_type.replace('C', 'A'));
              this.counters.aquabox[aquabox.aquabox_type]++;
              aquabox.item(1).set('opacity',0);
              aquabox.item(0).set('opacity',1);

            }

            let calulations = new Calculator(this.counters).calculateAll();
            this.$emit('caluclations', calulations);
            
            
            this.canvas.renderAll();
        }
      }
    },

    handleMouseDown(options) {
      if (this.drawingObject.type == "roof") {
          if(this.lineIsValid){
            this.canvas.selection = false;
            if (!this.drawingLine) {
              this.setStartingPoint(options); // set x,y
              this.drawingLine = true;
            } else {
              this.setPoint(options); // start click
            }
            this.roofPoints.push(new Point(this.x, this.y));
            var points = [this.x, this.y, this.x, this.y];

            let line = new fabric.Line(points, {
                strokeWidth: 3,
                selectable: false,
                stroke: 'blue',
              })
            
            this.lines.push(line);
            this.canvas.add(this.lines[this.lineCounter]);
            this.lineCounter++;

        }
      }
    },

    handleMouseDownScale(options) {
      if (this.drawingObject.type == "roof") {
        if(this.lineIsValid){
          this.canvas.selection = false;
          if (!this.drawingLine) {
              console.log('odje')
              this.setStartingPoint(options); // set x,y
              this.drawingLine = true;
            } else {
              this.setPointScale(options); // start click
            }
            this.roofPoints.push(new Point(this.x, this.y));
            var points = [this.x, this.y, this.x, this.y];

            let line = new fabric.Line(points, {
                strokeWidth: 3,
                selectable: false,
                stroke: 'orange',
              })
            
            this.scalingLines.push(line);
            this.canvas.add(this.scalingLines[0]);

        }
      }
    },

    handleMouseUp(){
        this.canvas.selection = true;
        this.canvas.requestRenderAll();
    },

    handleMouseMove(options){
         if (this.lines[0] !== null && this.lines[0] !== undefined && this.drawingObject.type == "roof") {
            this.setPoint(options)

            this.lines[this.lineCounter - 1].set({
                x2: this.x,
                y2: this.y
            });
            

            this.canvas.renderAll();
            if(this.lineCounter > 1 )
                this.intersect()

            if(this.lineIsValid) 
                this.lines[this.lineCounter - 1].set({'stroke':'blue'});
            else
                this.lines[this.lineCounter - 1].set({'stroke':'red'});

           
        }
    },
    handleMouseMoveScale(options){
         if (this.scalingLines[0] !== null && this.scalingLines[0] !== undefined && this.drawingObject.type == "roof") {
            this.setPointScale(options)

            this.scalingLines[0].set({
                x2: this.x,
                y2: this.y
            });
            

            this.canvas.renderAll();
            if(this.lineCounterScale > 1 )
                this.intersect()

            if(this.lineIsValid) 
                this.scalingLines[0].set({'stroke':'orange'});
            else
                this.scalingLines[0].set({'stroke':'red'});

           
        }
    },

    // Bug Reset zoom when drawing this.canvas.setZoom(1);
    handleZoom(opt){
      var delta = opt.e.deltaY;
      var zoom = this.canvas.getZoom();
      zoom *= 0.999 ** delta;
      if (zoom > 20) zoom = 20;
      if (zoom < 0.01) zoom = 0.01;
      this.canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
      opt.e.preventDefault();
      opt.e.stopPropagation();
    },

    handleStartPanning(opt){
      var evt = opt.e;
      if (evt.altKey === true) {
        this.isDragging = true;
        this.selection = false;
        this.lastPosX = evt.clientX;
        this.lastPosY = evt.clientY;
      }
    },

    handlePanning(opt){
      if (this.isDragging) {
        var e = opt.e;
        var vpt = this.canvas.viewportTransform;
        vpt[4] += e.clientX - this.lastPosX;
        vpt[5] += e.clientY - this.lastPosY;
        this.canvas.requestRenderAll();
        this.lastPosX = e.clientX;
        this.lastPosY = e.clientY;
      }
    },

    handleStopPanning(opt){
      // on mouse up we want to recalculate new interaction
      // for all objects, so we call setViewportTransform
      if(this.isDragging){
        this.canvas.setViewportTransform(this.canvas.viewportTransform);
        this.isDragging = false;
        this.selection = true;
        
      }
    },

    setStartingPoint(options) {
        let pos = this.canvas.getPointer(options.e);

        this.x = pos.x;
        this.y = pos.y;

        this.lineStart.x = pos.x;
        this.lineStart.y = pos.y;
    },
    checkIfClicked(item, pos){
      let absoluteLeft = this.group.left + (1 + item.idPos[1]*(50*this.clickScale)) ;
      let absoluteTop = this.group.top + (1 + item.idPos[0]*(50* this.clickScale)) ;
      

      let points = {
        tl:{
          x: absoluteLeft,
          y: absoluteTop
        },
        tr:{
          x: absoluteLeft + 50* this.clickScale,
          y: absoluteTop
        },
        bl:{
          x: absoluteLeft,
          y: absoluteTop+50* this.clickScale
        }
      };
      let xCheck = points.tl.x < pos.x && points.tr.x > pos.x;
      let Ycheck = points.tl.y < pos.y && points.bl.y > pos.y;

      if(xCheck && Ycheck){
        return true;
        }

        
      else return false;

    },
    
    setPoint(options){
        let pos = this.canvas.getPointer(options.e);

        let tempX = pos.x;
        let tempY= pos.y;
        
        if (Math.abs(tempX - this.lines[this.lineCounter - 1].x1) > Math.abs(tempY - this.lines[this.lineCounter - 1].y1)) {
            // Horizontal direction
            this.x = tempX;
            this.y = this.lines[this.lineCounter - 1].y1;
        } else {
            // Vertical direction
            this.x = this.lines[this.lineCounter - 1].x1;
            this.y = tempY;
        }
    },
    setPointScale(options){
        let pos = this.canvas.getPointer(options.e);

        let tempX = pos.x;
        let tempY= pos.y;
        
        if (Math.abs(tempX - this.scalingLines[0].x1) > Math.abs(tempY - this.scalingLines[0].y1)) {
            // Horizontal direction
            this.x = tempX;
            this.y = this.scalingLines[0].y1;
        } else {
            // Vertical direction
            this.x = this.scalingLines[0].x1;
            this.y = tempY;
        }
    },

    makeRoof(roofPoints) {



        let diffX = Math.abs(roofPoints[0].x - roofPoints[this.lineCounter -1].x);
        let diffY = Math.abs(roofPoints[0].y - roofPoints[this.lineCounter -1].y);


        if(this.lineCounter%2 == 1){
          if(diffX > diffY){
            roofPoints.push(new Point(roofPoints[this.lineCounter-1].x,roofPoints[0].y))
            
          }
          else
            roofPoints.push(new Point(roofPoints[0].x,roofPoints[this.lineCounter-1].y))



          roofPoints.push(new Point(roofPoints[0].x,roofPoints[0].y))
        }
        else{
          if(diffX > diffY)
              roofPoints[this.lineCounter -1].y = roofPoints[0].y;
          else
              roofPoints[this.lineCounter -1].x = roofPoints[0].x;

          roofPoints.push(new Point(roofPoints[0].x,roofPoints[0].y))
        }

        var roof = new fabric.Polygon(roofPoints, {
        fill: 'rgba(0,0,0,0)',
        stroke:'#58c',
        id:'container',
        });
        

        return roof;
    },

    findTopPaddingForRoof(roofPoints) {
        var result = 999999;
        for (var f = 0; f < this.lineCounter; f++) {
            if (roofPoints[f].y < result) {
                result = roofPoints[f].y;
            }
        }
        return Math.abs(result);
    },

    findLeftPaddingForRoof(roofPoints) {
        var result = 999999;
        for (var i = 0; i < this.lineCounter; i++) {
            if (roofPoints[i].x < result) {
                result = roofPoints[i].x;
            }
        }
        return Math.abs(result);
    },

    intersect() {
        let currentLine = this.lines[this.lineCounter - 1];

        let valid = true;
        // console.log(currentLine)
        this.canvas.forEachObject(function(obj) {
            var checkPrevious = () =>{
               let checkx = obj.x2 != currentLine.x1;
               let checky = obj.y2 != currentLine.y1;
               
               return checkx && checky
            }

            var checkNext = () =>{
               let checkx = obj.x1 != currentLine.x2;
               let checky = obj.y1 != currentLine.y2;
               
               return checkx && checky
            }
            if(obj != currentLine && checkPrevious() && checkNext()){

                if(currentLine.intersectsWithObject(obj,false,true)){
                    valid = false
                }
                else
                    valid = true

            }
        });
        this.lineIsValid = valid
    },

    generateAquabox(container, containerPoints){
        let aquaboxSide = 50* this.clickScale;

        let numbInWidth = Math.floor(container.width/(aquaboxSide));
        let numbInHeight = Math.floor(container.height/(aquaboxSide));

        let leftPreset = container.left +1; 
        let topPreset =  container.top +1; 


        let aquaboxArray = [];
        let idArray = [];
        
        // console.log(numbInHeight, numbInWidth);

        for(let i = 0; i< numbInHeight; i++){
          for(let j = 0; j < numbInWidth; j++){
            

              let aquabox = this.generateSingleAquabox(topPreset + i*(aquaboxSide), leftPreset + j*(aquaboxSide),aquaboxSide, [i,j], 'A')

              let points = aquabox.getCoords();
              let inside = true;
              for(let point of points){
                if(!this.inside(point, containerPoints))
                  inside = false;
              }
              if(!inside)
                  this.canvas.remove(aquabox)
              else{
                aquaboxArray.push(aquabox)
                idArray.push(aquabox.idPos)
              }
          }
        }


        console.log(numbInHeight, numbInWidth);

       
        // Start recursion
        // this.showAquabox(0,0, numbInHeight, numbInWidth,container, containerPoints, aquaboxArray, idArray, aquaboxSide, topPreset, leftPreset);
       
        return {items: aquaboxArray , array: idArray};
    },

    showAquabox(i, j, numbInHeight,numbInWidth,container, containerPoints, aquaboxArray, idArray, aquaboxSide, topPreset, leftPreset) {
          // Stop recursion when cells are processed
        if (i >= numbInHeight) {
            return;
        }

        // If end of a row, move to the next row
        if (j >= numbInWidth) {
            this.showAquabox(i + 1, 0, numbInHeight, numbInWidth,container, containerPoints, aquaboxArray, idArray, aquaboxSide, topPreset, leftPreset);
            return;
        }

        // Generate single aquabox
        let aquabox = this.generateSingleAquabox(topPreset + i*(aquaboxSide), leftPreset + j*(aquaboxSide),aquaboxSide, [i,j], 'A');
        let points = aquabox.getCoords();
        let inside = true;

        // Check if aquabox is inside the container
        for (let point of points) {
            if (!this.inside(point, containerPoints)) {
                inside = false;
            }
        }

        // If aquabox is not inside remove it
        if(!inside)
          this.canvas.remove(aquabox)
        else{
          aquaboxArray.push(aquabox)
          idArray.push(aquabox.idPos)
        }

        // Move to next column
        this.showAquabox(i, j + 1, numbInHeight, numbInWidth,container, containerPoints, aquaboxArray, idArray, aquaboxSide, topPreset, leftPreset);
      },

    generateSingleAquabox(top,left,length,position,type){
      // console.log(length)
        let group= new fabric.Group([], {
            width: length,
            height: length,
            left: left,
            top: top,
            idPos: position,
            evented: true,
            aquabox_type:type,
          });

          let blue = new fabric.Image(document.getElementById('aquabox'), {
              width: 50,
              height: 50,
              originX: 'center',
              originY: 'center',
              scaleX: this.clickScale,
              scaleY: this.clickScale,

          });

          let red = new fabric.Image(document.getElementById('cube'), {
              width: 50,
              height: 50,
              originX: 'center',
              originY: 'center',
              opacity: 0,
               scaleX: this.clickScale,
              scaleY: this.clickScale,
          });
          group.add(blue); 
          group.add(red); 
          

          return group
          
    },

  determineAquaboxType(aquabox,array){
    let typeNumber = 4;
    
    let adjacents = [
      [aquabox.idPos[0]+1,aquabox.idPos[1]],
      [aquabox.idPos[0]-1,aquabox.idPos[1]],
      [aquabox.idPos[0],aquabox.idPos[1]+1],
      [aquabox.idPos[0],aquabox.idPos[1]-1],
    ]
    for( let i = 0 ; i < adjacents.length; i++){
      if(array.some(a => adjacents[i].every((v,j) => v === a[j])))
        typeNumber--;
    }
    let type = `A${typeNumber}`;
    this.counters.aquabox[type] = this.counters.aquabox[type]+1;
    aquabox.aquabox_type = type
  },

  async scaleDownAquabox(){

    // this is retarded, but ok
    while(this.group._objects.length > 1){
      for(let i = 1; i < this.group._objects.length; i++){
         this.group.remove(this.group.item(i))
      }
    }

    let polygonContainer;

    this.group.item(0).clone(clone => polygonContainer = clone);

    polygonContainer.left = this.group.left;
    polygonContainer.top = this.group.top;

    this.canvas.remove(this.group); 
		this.canvas.remove(...this.canvas.getObjects());
    
    let newGroup = new fabric.Group([],{
      height: this.group.height,
          width: this.group.width,
          left: this.group.left,
          top: this.group.top,
          hasControls: false,
        })

    this.canvas.add(polygonContainer)
    
    let containerPoints = this.getPolygonPoints(polygonContainer)
    debugger;
    let aquaboxGenerationData = this.generateAquabox(polygonContainer, containerPoints);

    for(let item of aquaboxGenerationData.items){
      this.determineAquaboxType(item,aquaboxGenerationData.array);
    }
        
    newGroup.add(polygonContainer)

    for(let item of aquaboxGenerationData.items){
      newGroup.add(item)
      this.canvas.remove(item)
    }
    this.canvas.remove(polygonContainer)
    this.group = newGroup;
    this.canvas.add(this.group); 
    // Render polygon with aquabox
    let container = this.group.item(0);

    container.left = -(this.group.width /2);
    container.top = -(this.group.height /2);

		let lengths = this.showLineLenghts(containerPoints);

		for(let item of lengths){
			this.group.add(item)
		}
		
    let lineStartIndex = this.group._objects.length- lengths.length;
		
		for(let i = 1; i < this.group._objects.length; i++){
        let item = this.group.item(i);
        if(item.aquabox_type){
          item.left = -(this.group.width /2) + item.idPos[1]*(50 * this.clickScale);
          item.top= -(this.group.height /2) + item.idPos[0]*(50 * this.clickScale);
        }
        else{
          let deltaX = Math.abs(this.group.left - item.left);
          let deltaY = Math.abs(this.group.top - item.top);

          let offsetX = deltaX < 1 ? deltaX - item.width - 15  : deltaX - item.width/2;
          let offsetY = deltaY < 1 ? deltaY - item.height - 10  : deltaY - item.height/2;
					
					item.deltaX = deltaX;
					item.deltaY = deltaY;

						if(deltaX > 1 || deltaY > 1 && (i > lineStartIndex+1)){
						if(item.deltaX == this.group.item(lineStartIndex+1).deltaX){
							offsetY +=  item.height/2 + 10;
						}
						if(item.deltaY == this.group.item(lineStartIndex).deltaY){
							offsetX += item.width/2 + 10;
						}
					}

          let adjustedLeft = -(this.group.width /2) + offsetX;
          let adjustedTop = -(this.group.height /2) + offsetY;
          

          item.left = adjustedLeft;
          item.top= adjustedTop;

          if(item.top < 0 && item.deltaX == this.group.item(lineStartIndex+1).deltaX){
						item.top -= item.height;
					}
     
        }
		}
      // First bug (Fix rendering issues (Not showing aquabox elements on click))
    this.canvas.renderAll();
    document.querySelector('.scale-loader').classList.remove('open');

    let calulations = new Calculator(this.counters).calculateAll();
    this.$emit('caluclations', calulations);

  },
    
  inside(point, vs) {
    var x = point.x, y = point.y;
    
    var inside = false;
    for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
        var xi = vs[i][0], yi = vs[i][1];
        var xj = vs[j][0], yj = vs[j][1];
        
        var intersect = ((yi > y) != (yj > y))
            && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
        if (intersect) inside = !inside;
    }
    
    return inside;
  },
  getPolygonPoints(polygon){

    return polygon.get('points').map(function(p) {
      return [p.x,p.y];
    });
  },

  readBlob(blob) {
      return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.addEventListener('load', () => resolve(reader.result));
          reader.addEventListener('error', reject)
          reader.readAsDataURL(blob);
      })
  },

   getPdfHandler() {
      return pdfHandler;
  },

  async printPDF(pdfData, pageNumber) {
      const pdfjsLib = this.getPdfHandler();
      const canvas = document.getElementById('bg');
      pdfData = pdfData instanceof Blob ? await this.readBlob(pdfData) : pdfData;
      const data = atob(pdfData.startsWith(Base64Prefix) ? pdfData.substring(Base64Prefix.length) : pdfData);
      // Using DocumentInitParameters object to load binary data.
      const loadingTask = pdfjsLib.getDocument({ data });
      this.$emit('loadedPdf');
      return loadingTask.promise
          .then((pdf) => {
              return pdf.getPage(pageNumber)
                  .then((page) => {
                      //  retina scaling
                      const viewport = page.getViewport({ scale: window.devicePixelRatio });
                      const context = canvas.getContext('2d');

                      canvas.height = viewport.height
                      canvas.width = viewport.width;

                      // Render PDF page into canvas context
                      const renderContext = {
                          canvasContext: context,
                          viewport: viewport
                      };
                      const renderTask = page.render(renderContext);
                      return renderTask.promise.then(() => canvas);
                  });
          });
  },

  async pdfToImage(pdfData,pageNumber) {
      return await this.printPDF(pdfData,pageNumber)
          .then(c => {
              let img = new fabric.Image(c, {
                  id: 'background',
              })
              let can = this.canvas;
              can.setBackgroundImage(null, can.renderAll.bind(can));
              can.setBackgroundImage(img);
              can.renderAll();
              this.$emit('closePdf')
          });
  },

  async importPdf(pageNumber){
        await this.pdfToImage(this.pdfFile, pageNumber)
  },
  resetPolygon(){
    this.canvas.remove(this.group)
    
    this.group = null;
    this.drawingObject.type="roof";
    this.x = 0,
    this.y= 0,
    this.lineStart= {
        x:0,
        y:0
    };
    this.drawingLine = false;
    this.isDrawing = false;
		
		this.canvas.remove(...this.canvas.getObjects());
    this.canvas.off('mouse:up');
    this.canvas.off('mouse:down');
    this.canvas.off('mouse:move');
    this.canvas.off('mouse:dblclick');
   

    this.canvas.on('mouse:wheel', e => this.handleZoom(e));
    this.canvas.on('mouse:down', e => this.handleStartPanning(e))
    this.canvas.on('mouse:move', e => this.handlePanning(e))
    this.canvas.on('mouse:up', e => this.handleStopPanning(e))

    document.addEventListener('keydown', this.handleKeyDown);
    this.$emit('clearCalculations')
  },

  getCanvasSnapshot(){

    let vpt = this.canvas.viewportTransform
    vpt[0] = 1;
    vpt[1] = 0;
    vpt[2] = 0;
    vpt[3] = 1;
    vpt[4] = 0;
    vpt[5] = 0;

    this.canvas.setViewportTransform (vpt);
    this.canvas.renderAll();


    let snapshot = this.canvas.toDataURL({
      format: 'jpeg',
      top: this.group.top - 50,
      left: this.group.left - 100,
      width: this.group.width + 200,
      height: this.group.height + 100,
      });

      this.emitter.emit('sendSnapshot', snapshot)
  },

  openScalePopup() {
    this.isPopupVisible = true;
  },
  closeScalePopup() {
    this.isPopupVisible = false;
  },
  checkPopupChanges() {
    if (!this.scaleSetPopup && this.changesLoaded) {
      this.changesLoadedPopup = true;
      this.changesLoaded = false;
    }
  },
  validateInput() {
    this.isTouched = true;
  },
  toggleScaleBorderRed() {
    const val = !this.scaleBorderRed;
    this.$emit('ScaleBorderRedChange', val);
  },
  },
  computed:{
    getDimensions(){
      if (!this.group) {
        return {
          lenght: "/",
          width: "/"
        };
      }
        
      let lenghtPx = this.group.width;
      let widthPx = this.group.height;


      let compare = 50 * this.clickScale;
      let ratioLenght = lenghtPx / compare;
      let ratioWidth = widthPx / compare;
			if(this.units === 'metric') {
        
        return {
          lenght: Math.round(ratioLenght*75),
          width: Math.round(ratioWidth*75),
        }
      }
			else {
        return {
          lenght: Math.round(ratioLenght*75/2.54),
          width: Math.round(ratioWidth*75/2.54),
        }
      }
    },
    isValidLineLength() {
      const value = parseFloat(this.lineLengthInput);
      return !isNaN(value) && value >= 1;
    }
  },
   watch: {
      targetPage: function (val) {
       if(val){
        this.importPdf(val)
       }
      },
      isDrawing: function (state){
        if(state){
          this.canvas.off('mouse:up');
          this.canvas.off('mouse:move');
          this.canvas.off('mouse:down');

          this.canvas.on('mouse:wheel', e => this.handleZoom(e));
          this.canvas.on('mouse:down', e => this.handleMouseDown(e))
          this.canvas.on('mouse:up', this.handleMouseUp())
          this.canvas.on('mouse:move', e => this.handleMouseMove(e))
          // this.canvas.on('mouse:dblclick', e => this.handleDoubleClick(e))

          // Finish drawing on Enter
          document.addEventListener('keydown',this.handleKeyDown);
          

        }
        else{
          this.canvas.off('mouse:up');
          this.canvas.off('mouse:move');
          this.canvas.off('mouse:dblclick');


          this.canvas.on('mouse:wheel', e => this.handleZoom(e));
          this.canvas.on('mouse:down', e => this.handleStartPanning(e))
          this.canvas.on('mouse:move', e => this.handlePanning(e))
          this.canvas.on('mouse:up', e => this.handleStopPanning(e))
          
        }
      },
			// watch for units
			units: function (val) {
        if (!this.group) return;
				let objects = this.canvas.getObjects();
				let groupObj = this.group.getObjects();
				if(objects.length == 0) return;

				for(let i = 1; i < objects.length; i++){
					let item = objects[i];
					//this.canvas.remove(item);
					//this.group.remove(groupObj[groupObj.length - i]);
					//this.canvas.renderAll();
					let lineInGroup = groupObj[groupObj.length -i];
					lineInGroup.set('text', `${this.calculateLineText(lineInGroup.distanceToCalculate)} ${val === 'metric' ? 'cm' : 'in'}`);
				  item.set('text', `${this.calculateLineText(item.distanceToCalculate)} ${val === 'metric' ? 'cm' : 'in'}`);
					//this.group.add(item);
				}
					this.canvas.renderAll();
			},
      scaleSetPopup(val) {
        this.checkPopupChanges();
      },
      changesLoaded(val) {
        this.checkPopupChanges();
      },

      isPopupVisible(val) {
        if (val) {
          // After the popup becomes visible, focus the input
          this.$nextTick(() => {
            // Focus the input only if it's available
            if (this.$refs.modalInput) {
              this.$refs.modalInput.focus();
            }
          });
        }
      },

      // changesLoadedPopup(val) {
      //   if (val) {
      //     this.$nextTick(() => {
      //       this.$refs.changesLoadedButton.focus();
      //     });
      //   }
      // },
      // drawLinePopup(val) {
      //   if (val) {
      //     this.$nextTick(() => {
      //       this.$refs.drawLineButton.focus();
      //     });
      //   }
      // },
      // lineScaleSetPopup(val) {
      //   if (val) {
      //     this.$nextTick(() => {
      //       this.$refs.lineScaleSetButton.focus();
      //     });
      //   }
      // },

      isScaling: function (state){
        if(state){
          // alert("Please draw a line and input it's length on the plan (ex: 1050)")
          this.drawLinePopup = true;
          
          this.canvas.off('mouse:up');
          this.canvas.off('mouse:move');
          this.canvas.off('mouse:down');

          this.canvas.on('mouse:wheel', e => this.handleZoom(e));
          this.canvas.on('mouse:down', e => this.handleMouseDownScale(e))
          this.canvas.on('mouse:up', this.handleMouseUp())
          this.canvas.on('mouse:move', e => this.handleMouseMoveScale(e))
          // this.canvas.on('mouse:dblclick', e => this.handleDoubleClick(e))

          // Finish drawing on Enter
          document.removeEventListener('keydown',this.handleKeyDown);
          document.addEventListener('keydown',this.handleKeyDownScale);
          

        }
        else{
          this.canvas.off('mouse:up');
          this.canvas.off('mouse:move');
          this.canvas.off('mouse:dblclick');


          this.canvas.on('mouse:wheel', e => this.handleZoom(e));
          this.canvas.on('mouse:down', e => this.handleStartPanning(e))
          this.canvas.on('mouse:move', e => this.handlePanning(e))
          this.canvas.on('mouse:up', e => this.handleStopPanning(e))
          
          
        }
      },
      aquaboxScale: function(val){
        this.clickScale = val;

        if(this.group){
          this.scaleDownAquabox(val);
          // alert('changes loaded')

          this.changesLoaded = true;
        }
        else
        document.querySelector('.scale-loader').classList.remove('open');
        
      }
     
    }
};
</script>
